import { Observable, Subscribable } from "knockout"
import WCCEntity from './entity'

export interface JSONTag {
    ContentTagId?: string
    TagValue?: string
    TagColor?: string
    DiscussionId?: string
    Question?: string
    Visibility?: number
    SpecialType?: number
}

export class Tag extends WCCEntity<JSONTag> {
    contentTagId: Observable<string | undefined>
    tagValue: Observable<string>
    tagNoRootValueShort: Observable<string>
    tagColor: Observable<string>
    discussionId: Observable<string | undefined>
    question: Observable<string | undefined>
    visibility: Observable<number>
    specialType: Observable<number>

    tagValueShort: Subscribable<string>

    fontColor = ko.pureComputed(() => this.tagColor() ? system.calculateFontColor(this.tagColor()) : undefined);
    tagFontColor = this.fontColor;

    isHidden = ko.pureComputed(() => this.visibility() == enums.ContentTagVisibilityType.Hidden.value);
    isComplex = ko.pureComputed(() => this.tagValue().indexOf(':') != -1);
    tagRoot = ko.pureComputed(() => this.isComplex() ? this.tagValue().split(':')[0].toLowerCase() : undefined);
    tagParts = ko.pureComputed(() => this.isComplex() ? this.tagValue().split(':') : []);

    isAlreadyAttached = ko.observable(false);
    
    // Tag becomes active when it's being clicked. We need to apply some styles to it to fix dropdown z-index issue
    oIsActive = ko.observable(false);

    constructor(data?: JSONTag) {
        super();

        this.contentTagId = this.createField(data, 'ContentTagId');
        this.tagValue = this.createField(data, 'TagValue', '');
        this.tagColor = this.createField(data, 'TagColor', settings.tagDefaultColor);
        this.discussionId = this.createField(data, 'DiscussionId');
        this.question = this.createField(data, 'Question');
        this.visibility = this.createField(data, 'Visibility', settings.tagDefaultVisibility ?? enums.ContentTagVisibilityType.AdministratorsOnly.value);
        this.specialType = this.createField(data, 'SpecialType', enums.ContentTagSpecialType.None.value);

        this.tagValueShort = this.tagValue.shortcut(settings.maxTagValueDisplayLength(), true);
        this.tagNoRootValueShort = this.tagValueShort.mapSingle(value => this.isComplex() ? value.replace(new RegExp(`${this.tagRoot()}:`, 'i'), '') : value);
    }

    static create(jsonTag: JSONTag) {
        return new Tag(jsonTag);
    }
}