import { WCCStorageManager } from 'managers/storage';
import { IWCCStorageManager } from 'managers/iStorage';
import DiscussionManager from './discussion';

const storage: IWCCStorageManager = new WCCStorageManager();

const discussionsManager = storage.get(DiscussionManager, {});
const discussion = discussionsManager.pluck(m => m.discussion);

/**
 * Using this class to inject a custom redirect button. Should be run on topic.ThouroughDescription property before tags are parsed.
 * Intention is to use this to leave Qualzy app to another site that handles external third party requirements (like closing an embedded dialog/window) 
 */
export class CustomScriptInjector {

    injectRedirectButton(strInput: string) {
        const redirectUrl = discussion.pluck(d => d.redirectURL, '');
        const redirectText = discussion.pluck(d => d.redirectURLText, labels.RedirectToUrl);

        if (redirectUrl()) {
            return strInput.replace(new RegExp('##REDIRECTBUTTON##', 'g'), `<div class="redirect-button-container"><button class="redirect-button" onClick="window.location.href=\'${redirectUrl()}\'">${redirectText()}</button></div>`);
        }
        else
            return strInput;
    }
}

const customScriptInjector = new CustomScriptInjector();

export default customScriptInjector;