import Repository from 'repositories/generic';
import { BaseService } from 'services/base';
import endpoint from "../endpoints/api";
import {BrowseFilter} from "../models/filters/browseFilter";
import { IVideoInterviewJson } from '../pages/admin/discussions/projectDashboard/pages/videoInterviews/models/videoInterview';

export class InterviewsService extends BaseService {
    private repository = new Repository(endpoint);


    queries = {
        interviews: (discussionId: string) => this.repository.query<never, never, BrowseFilter, never, never, never, never>(`v3/discussions/${discussionId}/interviews`),
        createinterview: (discussionId: string) => this.repository.query<never, never, IVideoInterviewJson, never, never, IVideoInterviewJson, never>(`v3/discussions/${discussionId}/createinterview`),
    }

    /**
     * Gets a filtered list of interviews in their groups
     */
    getInterviewsFiltered(discussionId:string,  requestData:BrowseFilter) {
        return this.queries.interviews(discussionId).add(requestData);
    }

    saveInterview(discussionId: string, requestData: IVideoInterviewJson) {
        return this.queries.createinterview(discussionId).add(requestData);
    }
}