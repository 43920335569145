import { proxyLinkIfNeeded } from 'helpers/proxy';
import { Observable, PureComputed } from 'knockout';
import WCCEntity from './entity';

export const imageUserFileTypes: Array<number | undefined> = [
    enums.UserFileTypes.OriginalImage.value,
    enums.UserFileTypes.LQImage.value,
    enums.UserFileTypes.HQImage.value,
    enums.UserFileTypes.VideoThumbnail.value,
    enums.UserFileTypes.PendingVideoThumbnail.value
]

export const videoUserFileTypes: Array<number | undefined> = [
    enums.UserFileTypes.OriginalVideo.value,
    enums.UserFileTypes.LQVideo.value,
    enums.UserFileTypes.HQVideo.value,
    enums.UserFileTypes.VideoThumbnail.value,
    enums.UserFileTypes.PendingVideo.value
]

const realVideoUserFileTypes: Array<number | undefined> = [
    enums.UserFileTypes.OriginalVideo.value,
    enums.UserFileTypes.LQVideo.value,
    enums.UserFileTypes.HQVideo.value,
]

export interface JSONUserFile {
    UserFileId?: string
    Name?: string
    Token?: string
    Extension?: string
    Link?: string
    Hash?: string
    Content?: string
    Width?: number
    Height?: number    
    Type?: number
    State?: number
    CreateDate?: string
}

export default class UserFile extends WCCEntity<JSONUserFile> {
    userFileId: Observable<string | undefined>
    name: Observable<string | undefined>
    token: Observable<string | undefined>
    extension: Observable<string | undefined>
    link: Observable<string | undefined>
    hash: Observable<string | undefined>
    content: Observable<string | undefined>
    width: Observable<number | undefined>
    height: Observable<number | undefined>
    type: Observable<number | undefined>
    state: Observable<number | undefined>
    createDate: Observable<Date | undefined>

    defaultWidth = ko.pureComputed(() => 1280);
    defaultHeight = ko.pureComputed(() => 720);

    isImage: PureComputed<boolean>
    isVideo: PureComputed<boolean>
    isRealVideoFile: PureComputed<boolean>

    constructor(data?: JSONUserFile) {
        super();

        this.userFileId = this.createField(data, 'UserFileId');
        this.name = this.createField(data, 'Name');
        this.token = this.createField(data, 'Token');
        this.extension = this.createField(data, 'Extension');
        this.link = this.createField(data, 'Link').mapNotNull(link => proxyLinkIfNeeded(link));
        this.hash = this.createField(data, 'Hash');
        this.content = this.createField(data, 'Content');
        this.width = this.createField(data, 'Width');
        this.height = this.createField(data, 'Height');
        this.type = this.createField(data, 'Type');
        this.state = this.createField(data, 'State');
        this.createDate = this.createMappedField(data, 'CreateDate', WCCEntity.dateMapper);

        this.isImage = ko.pureComputed(() => imageUserFileTypes.includes(this.type()));
        this.isVideo = ko.pureComputed(() => videoUserFileTypes.includes(this.type()));
        this.isRealVideoFile = ko.pureComputed(() => realVideoUserFileTypes.includes(this.type()));
    }
}